<template>
  <div
    class="c-sticky-anchor"
    :class="{ 'c-sticky-anchor--visible': isVisible && isBelow }"
  >
    <a href="#top" class="c-sticky-anchor__link">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="sticky-anchor__arrow"
        width="12"
        height="27"
        viewBox="0 0 12 27"
        fill="none"
        style="margin-right: 10px"
      >
        <path
          d="M10.8618 6.8048L6.66667 2.60946V26.3333H5.33334V2.60946L1.13817 6.8048L0.195007 5.86163L6.00001 0.0571289L11.8048 5.8618L10.8618 6.8048Z"
          fill="white"
        />
      </svg>
      Naar boven
    </a>
  </div>
</template>

<script>
export default {
  name: "StickyAnchor",
  data() {
    return {
      isVisible: false,
      isBelow: false,
      observer: null,
    };
  },
  mounted() {
    const top = document.querySelector("#top");

    // Use the IntersectionObserver API to observe the target element

    this.observer = new IntersectionObserver(
      ([entry]) => {
        // If the element is visible, add the sticky class
        this.isVisible = !entry.isIntersecting;

        // If the element is below the viewport, add the below class
        this.isBelow = entry.boundingClientRect.y < 0;
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      }
    );

    this.observer.observe(top);
  },

  beforeDestroy() {
    this.observer.disconnect();
  },
};
</script>

<style lang="scss">
.c-sticky-anchor {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  transform: translateX(40px);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;

  @include fullscreen {
    right: 120px;
  }

  &--visible {
    transform: translateX(0);
    opacity: 1;
  }

  &__link {
    @include basic-reset;

    position: relative;
    display: inline-flex;
    align-items: center;
    border: none;
    background: none;
    padding: 12px 18px 12px 18px;
    text-decoration: none;
    color: $neo-btn-text-color;
    background: $bosch-blue;
    cursor: pointer;
    width: auto;
    outline: none;
    font-size: 16px;
    @include desktop {
      max-width: 300px;
    }
    @include on-event {
      outline: none;
      color: white;
      background: $bosch-blue-hover;
      &:before {
        left: 6px;
      }
      &:after {
        right: 6px;
      }
    }
  }
}
</style>
